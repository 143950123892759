/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Slide from "react-reveal";

export default function Video({ video }) {
    const youTube = video.youtube.map((item, i) => {
        return (
            <div key={i}>
                <h3>{item.description}</h3>
                <p className="info">
                    {item.date} <span>&bull;</span>
                    <em className="date">{item.location}</em>
                </p>
                <p>{item.long_description}</p>

                <iframe
                    className="frame-video"
                    src="https://www.youtube.com/embed/Vm8s7-SEBk8?si=iPnZww6ROXvRXRXV&amp;clip=UgkxFU7Ei2Ebls5pZ-T0G0ZbbxD_CbF20Xz-&amp;clipt=EI8TGKaIAQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
        );
    });

    return (
        <section id="resume">
            <Slide left duration={1300}>
                <div
                    className="row education"
                    css={css`
                        background-image: linear-gradient(
                            to bottom left,
                            white,
                            #ebeeee
                        );
                        border-radius: 25px;
                        padding: 24px;
                    `}
                >
                    <div className="three columns header-col">
                        <h1>
                            <span>YouTube</span>
                        </h1>
                    </div>

                    <div className="nine columns main-col">
                        <div className="row item">
                            <div className="twelve columns">{youTube}</div>
                        </div>
                    </div>
                </div>
            </Slide>
        </section>
    );
}
