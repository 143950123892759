/** @jsxImportSource @emotion/react */
import React, { useMemo, useState, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import ImageGallery from "react-image-gallery";

const indexes = [0, 54, 61, 61];

export default function Swiper({ books, expanded }) {
    const [page, setPage] = useState(1);
    const [bookNo, setBookNo] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [playing, setPlaying] = useState(false);

    const sliderRef = useRef();

    const PlayerButtons = () => {
        return (
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: stretch;
                    flex-wrap: wrap;
                    padding-top: 8px;
                `}
            >
                {/* <div className="non-mobile">
                    <a
                        className={`button btn slider-inactive-btn`}
                        onClick={() => {
                            sliderRef.current?.slideToIndex(0);
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-backward" />
                    </a>
                </div> */}
                <div>
                    <a
                        className={`button btn slider-inactive-btn`}
                        onClick={() => {
                            const index = sliderRef.current?.getCurrentIndex();
                            sliderRef.current?.slideToIndex(index - 1);
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-backward-step" />
                    </a>
                </div>
                <div>
                    <a
                        className={`button btn ${
                            !playing
                                ? "slider-disabled-btn"
                                : "slider-inactive-btn"
                        }`}
                        onClick={() => {
                            setPlaying(false);
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-circle-pause" />
                    </a>
                </div>
                <div>
                    <a
                        className={`button btn ${
                            playing
                                ? "slider-disabled-btn"
                                : "slider-inactive-btn"
                        }`}
                        onClick={() => {
                            setPlaying(true);
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-circle-play" />
                    </a>
                </div>
                <div>
                    <a
                        className={`button btn slider-inactive-btn`}
                        onClick={() => {
                            const index = sliderRef.current?.getCurrentIndex();
                            sliderRef.current?.slideToIndex(index + 1);
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-forward-step" />
                    </a>
                </div>
                {/* <div className="non-mobile">
                    <a
                        className={`button btn slider-inactive-btn`}
                        onClick={() => {
                            sliderRef.current?.slideToIndex(
                                indexes[bookNo] - 1
                            );
                        }}
                        css={css`
                            cursor: ${isLoading ? "not-allowed" : ""};
                            min-width: ${expanded ? "70%" : "100%"};
                        `}
                    >
                        <i className="fa fa-forward" />
                    </a>
                </div> */}
            </div>
        );
    };

    const images = useMemo(() => {
        return isLoading
            ? []
            : [...Array(indexes[bookNo])].map((e, index) => {
                  return {
                      original: `/images/swiper/book${bookNo}/${index}.png`,
                      thumbnail: `/images/swiper/book${bookNo}/${index}.png`,
                      originalClass: "book-image",
                  };
              });
    }, [bookNo, isLoading]);

    const pageRandom = useMemo(
        () => (page < 3 ? page : page * 2 + Math.floor(Math.random() * 2)),
        [page]
    );

    const cacheImages = async (images) => {
        const promises = await images.map((image) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();

                img.src = image;
                img.onload = resolve();
                img.onerror = reject();
            });
        });

        await Promise.all(promises);
        setIsLoading(false);
    };

    useEffect(() => {
        // preload the title page images
        const images = [
            "/images/swiper/book1/0.png",
            "/images/swiper/book2/0.png",
            "/images/swiper/book3/0.png",
        ];
        cacheImages(images);
    }, []);

    // useEffect(() => {
    //     setPlaying(false);
    //     setTimeout(() => {
    //         setPlaying(true);
    //     }, 3000);
    // }, [bookNo]);

    useEffect(() => {
        if (playing) sliderRef.current?.play();
        else sliderRef.current?.pause();
    }, [playing]);

    if (!images.length) return null;
    return (
        <div className="twelve columns main-col">
            <div className="item">
                {expanded ? (
                    <PlayerButtons />
                ) : (
                    <h2
                        css={css`
                            text-align: center;
                            margin-bottom: 36px;
                        `}
                    >
                        <ul>
                            Start insulating your home <em>fast</em> with
                            practical, hands-on advice:
                        </ul>
                    </h2>
                )}

                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        grid-gap: 8px;
                        justify-content: space-between;
                        align-items: stretch;
                        flex-wrap: wrap;
                    `}
                >
                    <div
                        css={css`
                            flex-grow: 3;
                        `}
                        className={expanded ? "swiper-large" : ""}
                    >
                        {page > 1 ? (
                            <div
                                css={css`
                                    font-style: italic;
                                    font-weight: bold;
                                    font-size: 70%;
                                    position: absolute;
                                    left: ${expanded ? "420px" : "218px"};
                                    z-index: 100;
                                    padding-top: 8px;
                                `}
                            >
                                Page: {pageRandom}
                            </div>
                        ) : null}

                        {!playing ? (
                            <div
                                css={css`
                                    font-style: italic;
                                    font-weight: bold;
                                    font-size: 70%;
                                    position: absolute;
                                    left: ${expanded ? "420px" : "218px"};
                                    z-index: 100;
                                    padding-top: 220px;
                                    opacity: 70%;
                                `}
                            >
                                <a
                                    className={`button btn ${
                                        playing
                                            ? "slider-disabled-btn"
                                            : "slider-inactive-btn"
                                    }`}
                                    onClick={() => {
                                        setPlaying(true);
                                    }}
                                    css={css`
                                        cursor: ${isLoading
                                            ? "not-allowed"
                                            : ""};
                                        min-width: ${expanded ? "70%" : "100%"};
                                    `}
                                >
                                    <i className="fa fa-circle-play" />
                                </a>
                            </div>
                        ) : (
                            <>
                                {/* <div
                                    css={css`
                                        font-style: italic;
                                        font-weight: bold;
                                        font-size: 70%;
                                        position: absolute;
                                        left: ${expanded ? "420px" : "218px"};
                                        z-index: 100;
                                        padding-top: 220px;
                                        opacity: 70%;
                                    `}
                                >
                                    <a
                                        className={`button btn ${
                                            playing
                                                ? "slider-disabled-btn"
                                                : "slider-inactive-btn"
                                        }`}
                                        onClick={() => {
                                            const index =
                                                sliderRef.current?.getCurrentIndex();
                                            sliderRef.current?.slideToIndex(
                                                index - 1
                                            );
                                        }}
                                        css={css`
                                            cursor: ${isLoading
                                                ? "not-allowed"
                                                : ""};
                                            min-width: ${expanded
                                                ? "70%"
                                                : "100%"};
                                        `}
                                    >
                                        <i className="fa fa-backward-step" />
                                    </a>
                                </div> */}
                                <div
                                    css={css`
                                        font-style: italic;
                                        font-weight: bold;
                                        font-size: 70%;
                                        position: absolute;
                                        left: ${expanded ? "420px" : "218px"};
                                        z-index: 100;
                                        padding-top: 220px;
                                        opacity: 70%;
                                    `}
                                >
                                    <a
                                        className={`button btn ${
                                            playing
                                                ? "slider-disabled-btn"
                                                : "slider-inactive-btn"
                                        }`}
                                        onClick={() => {
                                            setPlaying(false);
                                        }}
                                        css={css`
                                            cursor: ${isLoading
                                                ? "not-allowed"
                                                : ""};
                                            min-width: ${expanded
                                                ? "70%"
                                                : "100%"};
                                        `}
                                    >
                                        <i className="fa fa-circle-pause" />
                                    </a>
                                </div>
                                {/* <div
                                    css={css`
                                        font-style: italic;
                                        font-weight: bold;
                                        font-size: 70%;
                                        position: absolute;
                                        left: ${expanded ? "420px" : "218px"};
                                        z-index: 100;
                                        padding-top: 220px;
                                        opacity: 70%;
                                    `}
                                >
                                    <a
                                        className={`button btn ${
                                            playing
                                                ? "slider-disabled-btn"
                                                : "slider-inactive-btn"
                                        }`}
                                        onClick={() => {
                                            const index =
                                                sliderRef.current?.getCurrentIndex();
                                            sliderRef.current?.slideToIndex(
                                                index + 1
                                            );
                                        }}
                                        css={css`
                                            cursor: ${isLoading
                                                ? "not-allowed"
                                                : ""};
                                            min-width: ${expanded
                                                ? "70%"
                                                : "100%"};
                                        `}
                                    >
                                        <i className="fa fa-forward-step" />
                                    </a>
                                </div> */}
                            </>
                        )}

                        <ImageGallery
                            ref={sliderRef}
                            items={images}
                            thumbnailPosition="left"
                            autoPlay={false}
                            showFullscreenButton={false}
                            showNav={false}
                            showPlayButton={false}
                            onSlide={() => {
                                const index =
                                    sliderRef.current?.getCurrentIndex();
                                setPage(index);
                            }}
                            showThumbnails={false}
                            lazyLoad={false}
                        />
                    </div>{" "}
                    <div
                        css={css`
                            flex-grow: 1;
                            text-align: center;
                            padding-top: ${expanded ? "20px" : "0px"};

                            display: flex;
                            flex-direction: column;
                            grid-gap: 8px;
                            justify-content: space-between;
                            align-items: stretch;
                            flex-wrap: wrap;
                        `}
                    >
                        {/* {expanded ? null : <PlayerButtons />} */}

                        <div>
                            {books.map((project, i) => {
                                return (
                                    <div key={i} className="bookslist-item">
                                        <div>
                                            <a
                                                className={`smoothscroll button btn ${
                                                    i + 1 === bookNo
                                                        ? "slider-active-btn"
                                                        : "slider-inactive-btn"
                                                }`}
                                                onClick={() => {
                                                    setBookNo(i + 1);
                                                    sliderRef.current?.slideToIndex(
                                                        0
                                                    );
                                                }}
                                                css={css`
                                                    cursor: ${isLoading
                                                        ? "not-allowed"
                                                        : ""};
                                                    min-width: ${expanded
                                                        ? "70%"
                                                        : "100%"};
                                                `}
                                            >
                                                {i + 1 === bookNo &&
                                                !expanded ? (
                                                    <i className="fa fa-hand-o-left non-mobile" />
                                                ) : null}{" "}
                                                {project.title}
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
