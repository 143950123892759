/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";

export default function Books({ books, preview, setPreview }) {
    const [expanded, setExpanded] = useState({
        book1: false,
        book2: false,
        book3: false,
    });

    const [lookInside, setLookInside] = useState("");

    return books.map((project, i) => {
        const projectImage = "images/bookslist/" + project.image;
        const isExpanded = expanded[`book${i}`];
        return (
            <section key={i} className="book1" id={project.url}>
                <Fade left duration={1000} distance="40px">
                    <div
                        className="row education"
                        css={css`
                            border-radius: 25px;
                            padding: 24px;
                            border: 4px solid #11abb0;
                        `}
                    >
                        <>
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    grid-gap: 8px;
                                    justify-content: space-between;
                                    align-items: stretch;
                                    flex-wrap: wrap;
                                    padding-left: 24px;
                                `}
                            >
                                <div>
                                    <h3>{project.title}</h3>
                                </div>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: stretch;
                                        flex-wrap: wrap;
                                    `}
                                >
                                    <div>
                                        <a
                                            // href={project.amazonPage.com}
                                            className="button btn project-btn"
                                            onClick={() => {
                                                if (project.url === "book1")
                                                    window.gtag_report_conversion_1(
                                                        project.amazonPage.com
                                                    );
                                                if (project.url === "book2")
                                                    window.gtag_report_conversion_2(
                                                        project.amazonPage.com
                                                    );
                                                if (project.url === "book3")
                                                    window.gtag_report_conversion_3(
                                                        project.amazonPage.com
                                                    );
                                            }}
                                            // target="_blank"
                                            // rel="noreferrer"
                                            css={css`
                                                text-align: center;
                                                margin-right: 15px;
                                                min-width: 186px;
                                            `}
                                        >
                                            <i
                                                className="fa fa-amazon"
                                                css={css`
                                                    margin-right: 15px;
                                                `}
                                            ></i>
                                            Amazon.com
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            // href={project.amazonPage.uk}
                                            className="button btn project-btn"
                                            onClick={() => {
                                                if (project.url === "book1")
                                                    window.gtag_report_conversion_1(
                                                        project.amazonPage.uk
                                                    );
                                                if (project.url === "book2")
                                                    window.gtag_report_conversion_2(
                                                        project.amazonPage.uk
                                                    );
                                                if (project.url === "book3")
                                                    window.gtag_report_conversion_3(
                                                        project.amazonPage.uk
                                                    );
                                            }}
                                            // target="_blank"
                                            // rel="noreferrer"
                                            css={css`
                                                text-align: center;
                                                min-width: 186px;
                                            `}
                                        >
                                            <i
                                                className="fa fa-amazon"
                                                css={css`
                                                    margin-right: 15px;
                                                `}
                                            ></i>
                                            Amazon.co.uk
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div
                                css={css`
                                    width: 200px;
                                    position: absolute;
                                    bottom: -38px;
                                `}
                            >
                                <a
                                    className="button btn project-btn"
                                    css={css`
                                        height: 44px;
                                        min-height: 44px;
                                        padding: 12 20px;
                                        border-radius: 44px;
                                    `}
                                    title="Show more"
                                    onClick={() =>
                                        setExpanded({
                                            ...expanded,
                                            [`book${i}`]: isExpanded
                                                ? false
                                                : true,
                                        })
                                    }
                                >
                                    {isExpanded ? (
                                        <>
                                            <i class="fa-solid fa-minus"></i>{" "}
                                            Show less
                                        </>
                                    ) : (
                                        <>
                                            <i class="fa-solid fa-plus"></i>{" "}
                                            Show more
                                        </>
                                    )}
                                </a>
                            </div>
                            <div className={`three columns header-col`}>
                                {false && project?.url === "book2" ? (
                                    <>
                                        <iframe
                                            type="text/html"
                                            css={css`
                                                max-width: 100%;
                                                width: 336px;
                                                height: 550px;
                                            `}
                                            sandbox="allow-scripts allow-same-origin allow-popups"
                                            width="336"
                                            height="550"
                                            frameborder="0"
                                            allowfullscreen
                                            // style="max-width:100%"
                                            src="https://read.amazon.co.uk/kp/card?asin=B0CLL24X5L&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_K3DX6ETJM7PJ3GQRDCKP"
                                        ></iframe>
                                    </>
                                ) : (
                                    <>
                                        <h1>
                                            <span>
                                                <img
                                                    alt={project.title}
                                                    src={projectImage}
                                                    css={css`
                                                        max-width: 200px;
                                                        border-radius: 10px;
                                                    `}
                                                />
                                            </span>
                                        </h1>
                                        <div className="info">
                                            <ul>
                                                <li>
                                                    <h1>
                                                        {" "}
                                                        <span>&bull;</span>
                                                        Price: {project.price}
                                                    </h1>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                )}

                                {/* <div>
                                    <a
                                        href={`look-inside?book=${project.url}`}
                                        className="button btn project-btn nav-button-current"
                                        css={css`
                                            text-align: center;
                                            min-width: 186px;
                                        `}
                                    >
                                        <i
                                            className="fa fa-look"
                                            css={css`
                                                margin-right: 15px;
                                            `}
                                        ></i>
                                        Look inside
                                    </a>
                                </div> */}
                            </div>

                            <div className="nine columns main-col">
                                <div className="row item">
                                    <div className="twelve columns">
                                        <div className="info non-mobile">
                                            <ul>
                                                <li>
                                                    <h1>
                                                        {" "}
                                                        <span>&bull;</span>{" "}
                                                        Expertise level:{" "}
                                                        {project.skill}
                                                        /5
                                                    </h1>
                                                </li>
                                                <li>
                                                    <h1>
                                                        {" "}
                                                        <span>&bull;</span>{" "}
                                                        Project cost:{" "}
                                                        {project.cost}
                                                        /5
                                                    </h1>
                                                </li>
                                                <li>
                                                    <h1>
                                                        {" "}
                                                        <span>&bull;</span>{" "}
                                                        Warming effect:{" "}
                                                        {project.warmth}/5
                                                    </h1>
                                                </li>
                                            </ul>{" "}
                                        </div>
                                        {project.long_description.map(
                                            (paragraph, i) => (
                                                <p
                                                    key={i}
                                                    className={
                                                        i ===
                                                            project
                                                                .long_description
                                                                .length -
                                                                1 && !isExpanded
                                                            ? "fade-bottom"
                                                            : ""
                                                    }
                                                >
                                                    {paragraph}
                                                </p>
                                            )
                                        )}
                                        {isExpanded
                                            ? project.more.map(
                                                  (paragraph, i) => (
                                                      <p key={i}>{paragraph}</p>
                                                  )
                                              )
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </section>
        );
    });
}
