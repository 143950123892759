/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
// import "react-accessible-accordion/dist/fancy-example.css";

export default function Faq({ books }) {
    return (
        <>
            <section id="faq">
                <>
                    <Fade left duration={1000} distance="40px">
                        <div
                            className="row education"
                            css={css`
                                background-image: linear-gradient(
                                    to bottom left,
                                    white,
                                    #ebeeee
                                );
                                border-radius: 25px;
                                padding: 24px;
                            `}
                        >
                            <div className="twelve columns main-col">
                                <div className="item">
                                    <h1>FAQ</h1>
                                    <Accordion allowZeroExpanded={true}>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why should I read these
                                                    books?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The cost of heating our
                                                    homes in the 21st century is
                                                    on the rise. By insulating
                                                    our homes we can save money
                                                    on energy bills and enjoy a
                                                    better living environment,
                                                    with fewer drafts, less
                                                    dampness and less noise from
                                                    outside. A well-insulated
                                                    home will be warmer in
                                                    winter and also cooler in
                                                    summer. Good insulation can
                                                    even increase the value of
                                                    our house.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What skills will I need?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The ideal reader will be a
                                                    homeowner with their own
                                                    tools and enough DIY
                                                    experience to wire a socket
                                                    extension, dry line a wall
                                                    or do basic carpentry. A few
                                                    specialist skills, such as
                                                    plastering, may either have
                                                    to be farmed out or acquired
                                                    on the fly, although I have
                                                    also given some pointers to
                                                    aid the novice, should they
                                                    wish to give it a go. No
                                                    obscure materials are
                                                    required - everything needed
                                                    in this series of books will
                                                    be found in DIY shops and
                                                    builder's merchants.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Where in the world?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    This series uses British
                                                    houses as its reference, but
                                                    the content is as
                                                    internationalised as
                                                    possible. The principles of
                                                    good insulation are
                                                    universal. Although
                                                    construction methods differ
                                                    between Europe and North
                                                    America, you can still
                                                    encounter structural panels,
                                                    timber, stone and brick
                                                    walls anywhere in the world.
                                                    Providing the correct
                                                    insulation materials are
                                                    chosen, the designs here can
                                                    be used for most building
                                                    types everywhere.
                                                    Mmeasurements are given in
                                                    both millimetres and inches.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Will there be a print
                                                    version?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Yes - eta of the three
                                                    paperback versions is mid
                                                    Feb 2024
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </>
            </section>
        </>
    );
}
