/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Fade } from "react-reveal";

export default function Author({ main }) {
    return (
        <section id="author">
            <Fade left duration={1000} distance="40px">
                <div
                    className="row education"
                    css={css`
                        background-color: #000524;
                        border-radius: 25px;
                        padding: 24px;
                        border: 1px solid #e8e8e8;
                    `}
                >
                    <div className="three columns header-col">
                        <img
                            className="profile-pic"
                            src={"images/" + main.image}
                            alt="John Lennon"
                        />
                    </div>
                    <div className="nine columns main-col">
                        <h2>About the author</h2>

                        <p
                            css={css`
                                text-align: justify;
                            `}
                        >
                            {main.bio}
                        </p>
                    </div>
                    <div>
                        <a
                            href="#contact"
                            className="button btn project-btn"
                            css={css`
                                // min-width: 280px;
                                // min-height: 60px;
                                // font-size: 30px;
                                float: right;
                                padding: 12px;
                                text-align: center;
                                background-color: white !important;
                                color: #000524 !important;
                                :hover {
                                    color: #11abb0 !important;
                                }
                            `}
                        >
                            <i className="fa fa-email" /> Get in touch
                        </a>
                    </div>
                </div>
            </Fade>
        </section>
    );
}
