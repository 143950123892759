export const URLS = {
    OVERVIEW: "/",
    AUTHOR: "/author",
    BOOKS: "/books",
    VIDEO: "/video",
    CONTACT: "/contact",
};

export const SINGLE_PAGE_URLS = {
    OVERVIEW: "#home",
    AUTHOR: "#author",
    BOOKS: "#book1",
    VIDEO: "#resume",
    CONTACT: "#contact",
    FAQ: "#faq",
};

export const ENDPOINTS = {
    SP_CASES_SUMMARY: "/l/api/csm/sn_customerservice/sp/cases/summary",
    SP_CASES: "/l/api/csm/sn_customerservice/sp/cases",
    SP_CATALOG_ITEMS: "/l/api/csm/sn_customerservice/sp/catalog-items",
    SP_CATALOG_ITEMS_SUMMARY:
        "/l/api/csm/sn_customerservice/sp/catalog-items/summary",
};

export const EXTERNAL_URLS = {
    CASES: "/cases/",
    CASES_LIST: "/l/cases/explore",
    CASES_CREATE: "/cases/create/",
    CI: "/ci/:id/",
};

export const FEATURE_TAGS = {
    WIDGET_MODULE_CSM_GRAPHS: "widget-module-csm-graphs",
};

export const NO_DATA = "-";

export const SIDEMENU_SCHEMA = [
    {
        id: "support-cases",
        name: "app:title",
        link: URLS.OVERVIEW,
    },
    {
        id: "list",
        name: "app:explore-cases",
        link: URLS.EXPLORE,
    },
    {
        id: "case-create",
        name: "app:create-case",
        keepOpen: true,
        menu_items: [
            {
                id: "create",
                name: "app:create-case",
                link: URLS.CREATE,
            },
            {
                id: "create-beta",
                name: "app:create-case-beta",
                link: URLS.CREATE_BETA,
                "feature-tag": "thorium-sidemenu-create-case-beta",
            },
            {
                id: "explore-service-catalog",
                name: "app:explore-service-catalog",
                link: URLS.EXPLORE_SERVICE_CATALOG,
            },
        ],
    },
];

export const USER_CASES_FILTER =
    "contact=javascript:gs.getUserID()^ORrelated_party_users=javascript:gs.getUserID()";
