/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";
import { URLS, SINGLE_PAGE_URLS } from "../constants";

export default function Home({ main, books, preview, setPreview }) {
    const amazonSeriesHome = main.amazonSeriesHome;

    return (
        <>
            <div id="home">
                <div
                    className="row banner"
                    css={css`
                        padding-top: 48px;
                    `}
                >
                    {/* <Fade bottom> */}
                    <div
                        css={css`
                            padding: 24px;
                            background-image: linear-gradient(
                                to bottom left,
                                white,
                                #ebeeee
                            );
                            border-radius: 25px;
                            border: 4px solid #282934;
                        `}
                    >
                        <div
                            css={css`
                                padding: 0px 0px 0px 24px;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                grid-gap: 8px;
                                justify-content: space-between;
                                align-items: stretch;
                                flex-wrap: wrap;
                            `}
                        >
                            <div>
                                <span
                                    css={css`
                                        padding-bottom: 16px;
                                        font-weight: bold;
                                        font-size: 14px;
                                    `}
                                >
                                    insulation-guy.com - home of the book
                                    series:
                                </span>
                                <h2
                                    // className="mobile-only"
                                    // className="responsive-headline"
                                    css={css`
                                        padding-bottom: 12px;
                                    `}
                                >
                                    DIY Home Insulation
                                </h2>
                                {/* <h1
                                    className="non-mobile"
                                    // className="responsive-headline"
                                    css={css`
                                        padding-bottom: 12px;
                                    `}
                                >
                                    DIY Home Insulation
                                </h1> */}
                            </div>

                            <div>
                                <a
                                    // href={amazonSeriesHome}
                                    onClick={() =>
                                        window.gtag_report_conversion(
                                            amazonSeriesHome
                                        )
                                    }
                                    className="button btn project-btn"
                                    // target="_blank"
                                    // rel="noreferrer"
                                    css={css`
                                        float: right;
                                        min-width: 200px;
                                        min-height: 60px;
                                        font-size: 30px;
                                        padding: 12px 24px;
                                        text-align: center;
                                        z-index: 10001;
                                    `}
                                >
                                    <i className="fa fa-amazon" /> On Amazon
                                </a>
                            </div>
                        </div>
                        <Fade bottom>
                            <div
                                css={css`
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    grid-gap: 8px;
                                    justify-content: space-between;
                                    align-items: stretch;
                                    flex-wrap: wrap;
                                `}
                            >
                                <div
                                    css={css`
                                        width: 500px;
                                        text-align: left;
                                    `}
                                    className="columns"
                                >
                                    <div
                                        css={css`
                                            p {
                                                margin: 16px 0 0 0;
                                            }
                                        `}
                                    >
                                        <h4
                                            css={css`
                                                padding-bottom: 10px;
                                            `}
                                        >
                                            Three little kindle books that are
                                            big about being warm
                                        </h4>
                                        <ul>
                                            {books.map((book, i) => {
                                                return (
                                                    <p className="book-link">
                                                        <a
                                                            href={`#${book.url}`}
                                                            css={css`
                                                                font-weight: bold;
                                                            `}
                                                            key={
                                                                book.description
                                                            }
                                                        >
                                                            <span>•</span>{" "}
                                                            {book.title}
                                                            {": "}
                                                            {
                                                                book.description
                                                            }{" "}
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="columns">
                                    <img
                                        css={css`
                                            margin: 16px 0 0 0;
                                        `}
                                        className="books_home_image"
                                        src="images/home_page2.png"
                                    />
                                    {/* <div>
                                        <a
                                            className="button btn project-btn nav-button nav-button-current"
                                            onClick={() =>
                                                setPreview(preview ? 0 : 1)
                                            }
                                            css={css`
                                                float: right;
                                                // min-width: 280px;
                                                // min-height: 60px;
                                                // font-size: 30px;
                                                padding-top: 24px;
                                                text-align: center;
                                                z-index: 10001;
                                            `}
                                        >
                                            <i className="fa fa-book" /> Look
                                            inside
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </Fade>
                    </div>
                    {/* </Fade> */}
                </div>
            </div>
        </>
    );
}
