/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";

export default function MailingList({ books }) {
    return (
        <>
            <section>
                <>
                    <Fade left duration={1000} distance="40px">
                        <div
                            className="row education contact"
                            css={css`
                                border-radius: 25px;
                                padding: 36px 24px 0px 24px;
                                background-color: #282934;
                            `}
                        >
                            <div className="twelve columns main-col">
                                {" "}
                                <form
                                    action="/#home"
                                    method="get"
                                    id="contactForm"
                                    name="contactForm"
                                >
                                    <div className="item">
                                        <fieldset>
                                            <div
                                                css={css`
                                                    display: flex;
                                                    flex-direction: row;
                                                    grid-gap: 8px;
                                                    justify-content: space-between;
                                                    align-items: stretch;
                                                    flex-wrap: wrap;
                                                    padding-left: 24px;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        flex-grow: 2;
                                                        text-align: left !important;
                                                    `}
                                                >
                                                    <h2
                                                        css={css`
                                                            text-align: center;
                                                            color: #fff;
                                                        `}
                                                    >
                                                        Join the mailing list:
                                                    </h2>
                                                </div>
                                                <div
                                                    css={css`
                                                        flex-grow: 7;
                                                    `}
                                                >
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        id="contactName"
                                                        name="contactName"
                                                        placeholder="Enter an email address"
                                                        css={css`
                                                            width: 100% !important;
                                                        `}
                                                    />
                                                </div>
                                                <div
                                                    css={css`
                                                        flex-grow: 1;
                                                        text-align: right !important;
                                                    `}
                                                >
                                                    <button
                                                        css={css`
                                                            :hover {
                                                                color: white !important;
                                                            }
                                                        `}
                                                    >
                                                        Join
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </>
            </section>
        </>
    );
}
