/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";
import Swiper from "./Swiper";

export default function LookInside({ books }) {
    let params = new URLSearchParams(document.location.search);
    let name = params.get("book"); // is the string "Jonathan"

    // if (!preview) return null;

    return (
        <>
            <section id="bookslistXXX">
                <>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: stretch;
                            flex-wrap: wrap;
                        `}
                    >
                        <a
                            href={"/"}
                            className="button btn project-btn nav-button-current"
                            css={css`
                                text-align: center;
                                min-width: 186px;
                            `}
                        >
                            <i
                                className="fa fa-arrow-left"
                                css={css`
                                    margin-right: 15px;
                                `}
                            ></i>
                            insulation-guy.com
                        </a>

                        <div
                            className="image-gallery-slides"
                            css={css`
                                width: 100%;
                                height: 600px;
                            `}
                        >
                            <iframe
                                css={css`
                                    width: 92%;
                                    height: 100%;
                                `}
                                // className="frame-video"
                                src={`/resources/${name}.html`}
                                title="Look inside"
                            ></iframe>
                        </div>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: stretch;
                                flex-wrap: wrap;
                                padding-top: 20px;
                            `}
                        >
                            <a
                                href="https://www.amazon.com/dp/B0CLL24X5L"
                                className="button btn project-btn"
                                // onClick={() => {
                                //     if (project.url === "book1")
                                //         window.gtag_report_conversion_1(
                                //             project.amazonPage.com
                                //         );
                                //     if (project.url === "book2")
                                //         window.gtag_report_conversion_2(
                                //             project.amazonPage.com
                                //         );
                                //     if (project.url === "book3")
                                //         window.gtag_report_conversion_3(
                                //             project.amazonPage.com
                                //         );
                                // }}
                                // target="_blank"
                                // rel="noreferrer"
                                css={css`
                                    text-align: center;
                                    min-width: 186px;
                                `}
                            >
                                <i
                                    className="fa fa-amazon"
                                    css={css`
                                        margin-right: 15px;
                                    `}
                                ></i>
                                Amazon.com
                            </a>
                        </div>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: stretch;
                                flex-wrap: wrap;
                            `}
                        >
                            <a
                                href="https://www.amazon.com/dp/B0CLL24X5L"
                                className="button btn project-btn"
                                // onClick={() => {
                                //     if (project.url === "book1")
                                //         window.gtag_report_conversion_1(
                                //             project.amazonPage.uk
                                //         );
                                //     if (project.url === "book2")
                                //         window.gtag_report_conversion_2(
                                //             project.amazonPage.uk
                                //         );
                                //     if (project.url === "book3")
                                //         window.gtag_report_conversion_3(
                                //             project.amazonPage.uk
                                //         );
                                // }}
                                // target="_blank"
                                // rel="noreferrer"
                                css={css`
                                    text-align: center;
                                    min-width: 186px;
                                `}
                            >
                                <i
                                    className="fa fa-amazon"
                                    css={css`
                                        margin-right: 15px;
                                    `}
                                ></i>
                                Amazon.co.uk
                            </a>
                        </div>
                    </div>
                </>
            </section>
        </>
    );
}
