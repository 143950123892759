/** @jsxImportSource @emotion/react */
import React from "react";
import Header from "./Header";
import SinglePageHeader from "./SinglePageHeader";
import Footer from "./Footer";

export default function Wrapper({ main, children }) {
    return (
        <>
            {/* <Header main={main} /> */}
            <SinglePageHeader main={main} />
            {children}
            {/* <Footer /> */}
        </>
    );
}
