import React, { Component, useState } from "react";
// import ReactGA from "react-ga";
import {
    Switch,
    Route,
    Redirect,
    BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Author from "./Components/Author";
import Video from "./Components/Video";
import Contact from "./Components/Contact";
import Books from "./Components/Books";
import LookInside from "./Components/LookInside";
import Faq from "./Components/Faq";
import Wrapper from "./Components/Wrapper";
import MailingList from "./Components/MailingList";
import BooksList from "./Components/BooksList";
import resumeData from "./resumeData.json";

const MainPage = ({ data }) => {
    const [preview, setPreview] = useState(0);
    return (
        <>
            {" "}
            <Wrapper {...data}>
                <Home {...data} preview={preview} setPreview={setPreview} />
                <Books {...data} preview={preview} setPreview={setPreview} />
                {/* <LearnMore {...data} preview={preview} setPreview={setPreview} /> */}
                <BooksList
                    {...data}
                    preview={preview}
                    setPreview={setPreview}
                />
                <MailingList {...data} />
                <Faq {...data} />
                <Author {...data} />
                <Video {...data} />
                <Contact {...data} />{" "}
            </Wrapper>
        </>
    );
};

const App3 = ({ data }) => {
    const [preview, setPreview] = useState(0);
    return (
        <Router basename={"/"}>
            <Switch>
                <Route
                    path="/look-inside"
                    component={() => <LookInside {...data} />}
                />
                <Route path="/" component={() => <MainPage data={data} />} />
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            resumeData: {},
        };
    }

    render() {
        return resumeData.main ? (
            <div className="App">
                <App3 data={resumeData} />
            </div>
        ) : null;
    }
}

// const App2 = ({ data }) => {
//     return (
//         <Wrapper {...data}>
//             <Router basename={URLS.OVERVIEW}>
//                 <Switch>
//                     <Route
//                         path={URLS.OVERVIEW}
//                         component={() => <Home {...data} />}
//                         exact
//                     />
//                     <Route
//                         path={URLS.AUTHOR}
//                         component={() => <Author {...data} />}
//                     />
//                     <Route
//                         path={URLS.BOOKS}
//                         component={() => <Books {...data} />}
//                     />
//                     <Route
//                         path={URLS.VIDEO}
//                         component={() => <Video {...data} />}
//                     />
//                     <Route
//                         path={URLS.CONTACT}
//                         component={() => <Contact {...data} />}
//                     />
//                     <Redirect to={URLS.OVERVIEW} />
//                 </Switch>
//             </Router>
//         </Wrapper>
//     );
// };

export default App;
