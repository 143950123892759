/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { SINGLE_PAGE_URLS } from "../constants";

export default function Header({ main }) {
    const [page, setPage] = useState("home");

    return (
        <>
            <header id="home">
                <div
                    className="pull-left btn-toplink-full-size"
                    css={css`
                        position: fixed;
                        left: 40;
                        top: 4px;
                        z-index: 1000;
                    `}
                >
                    <div
                        css={css`
                            padding-left: 50px;
                        `}
                    >
                        <a
                            href={SINGLE_PAGE_URLS.OVERVIEW}
                            onClick={() => setPage("home")}
                            className={`smoothscroll button btn nav-button nav-button-${
                                page === "home" ? "current" : "home"
                            }`}
                            css={css`
                                height: 44px;
                                min-width: 180px;
                                border-radius: 44px;
                                padding: 4px 20px;
                            `}
                        >
                            insulation-guy.com
                        </a>
                    </div>
                </div>

                <nav id="nav-wrap" className="opaque">
                    <a
                        className="mobile-btn"
                        href="#nav-wrap"
                        title="Show navigation"
                    >
                        Show navigation
                    </a>
                    <a
                        className="mobile-btn"
                        href="#home"
                        title="Hide navigation"
                    >
                        Hide navigation
                    </a>

                    <ul id="nav" className="nav">
                        <li className={`mobile-only `}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "home" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.OVERVIEW}
                                onClick={() => setPage("home")}
                            >
                                home
                            </a>
                        </li>

                        <li className={page === "books" ? "current" : ""}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "books" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.BOOKS}
                                onClick={() => setPage("books")}
                            >
                                books
                            </a>
                        </li>
                        <li className={page === "author" ? "current" : ""}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "author" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.AUTHOR}
                                onClick={() => setPage("author")}
                            >
                                author
                            </a>
                        </li>
                        <li className={page === "faq" ? "current" : ""}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "faq" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.FAQ}
                                onClick={() => setPage("faq")}
                            >
                                faq
                            </a>
                        </li>
                        <li className={page === "video" ? "current" : ""}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "video" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.VIDEO}
                                onClick={() => setPage("video")}
                            >
                                video
                            </a>
                        </li>
                        <li className={page === "contact" ? "current" : ""}>
                            <a
                                className={`smoothscroll button btn nav-button nav-button-${
                                    page === "contact" ? "current" : "home"
                                }`}
                                href={SINGLE_PAGE_URLS.CONTACT}
                                onClick={() => setPage("contact")}
                            >
                                contact
                            </a>
                        </li>
                        {/* {page !== "home" ? (
                            <a
                                href={main.amazonSeriesHome}
                                className="button btn project-btn btn-toplink"
                                target="_blank"
                                css={css`
                                    float: right;
                                    padding: 2px 8px !important;
                                    margin-top: 6px !important;
                                    margin-right: 12px !important;
                                `}
                            >
                                <i className="fa fa-book" /> On Amazon
                            </a>
                        ) : null} */}
                    </ul>
                </nav>
            </header>
        </>
    );
}
