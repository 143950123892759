/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import Fade from "react-reveal";
import Swiper from "./Swiper";

export default function Books({ books, preview }) {
    const [expanded, setExpanded] = useState(false);
    const [bookNo, setBookNo] = useState(preview);

    return (
        <>
            <section id="bookslist" className="non-mobile">
                <>
                    <Fade left duration={3000} distance="40px">
                        <div
                            className={`row education ${
                                expanded ? "slider-expanded" : "slider-normal"
                            }`}
                            css={css`
                                background-image: linear-gradient(
                                    to bottom left,
                                    white,
                                    #ebeeee
                                );
                                border-radius: 25px;
                                padding: 24px;
                            `}
                        >
                            {/* <div
                                css={css`
                                    width: 40%;
                                    position: absolute;
                                    top: -22px;
                                `}
                            >
                                <a
                                    className={`button btn project-btn non-mobile ${
                                        expanded
                                            ? "slider-btn-expanded"
                                            : "slider-btn-normal"
                                    }`}
                                    css={css`
                                        width: 100%;
                                        height: 44px;
                                        min-height: 44px;
                                        padding: 4px 20px;
                                        border-radius: 44px;

                            
                                        }
                                    `}
                                    title="Show more"
                                    onClick={() => setExpanded(!expanded)}
                                >
                                    {expanded ? (
                                        <>
                                            <i class="fa-solid fa-magnifying-glass fa-l"></i>{" "}
                                            Zoom out
                                        </>
                                    ) : (
                                        <>
                                            <i class="fa-solid fa-magnifying-glass fa-xl"></i>{" "}
                                            Zoom in
                                        </>
                                    )}
                                </a>
                            </div> */}
                            {/* <div
                                css={css`
                                    // width: 40%;
                                    position: absolute;
                                    top: -22px;
                                `}
                            >
                                <div>
                                    <a
                                        className={`smoothscroll button btn slider-inactive-btn`}
                                        css={css`
                                                width: 100%;
                                                height: 44px;
                                                min-height: 44px;
                                                padding: 4px 20px;
                                                border-radius: 44px;
        
                                    
                                                }
                                            `}
                                    >
                                        {books[preview - 1].title}
                                    </a>
                                </div>
                            </div> */}

                            <Swiper
                                {...{
                                    books,
                                    expanded,
                                    setExpanded,
                                    bookNo,
                                    setBookNo,
                                }}
                            />
                        </div>
                    </Fade>
                </>
            </section>
        </>
    );
}
